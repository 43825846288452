/**
 * Prevents Angular change detection from
 * running with certain Web Component callbacks
 */
// eslint-disable-next-line no-underscore-dangle
(window as any).__Zone_disable_customElements = true;
// (window as any).__zone_symbol__UNPATCHED_EVENTS = ['resize']; // disable patch specified eventNames

// Para que lance la excepción pura
// esto lo agregue para buscar deadline-exceeded
// (window as any).__zone_symbol__DISABLE_WRAPPING_UNCAUGHT_PROMISE_REJECTION = true;